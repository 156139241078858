import { uuidFromCode, isNonEmptyString, removeAllButFields } from "./utils"

export const checkRoutingImportedData = (data, domains) => {
  const errors = new Set()

  try {
    data = typeof data === "string" && JSON.parse(data)
  } catch (err) {
    console.log(err)
    errors.add(`The file you provided is not in a JSON format`)
    return { data, errors }
  }

  if (!Array.isArray(data)) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }
  if (data.length === 0) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }

  const mfwType = ["ApiRoot", "TelescopicFQDN"]
  const directionType = ["Direct"]
  const dialogueModeType = ["inbound", "outbound"]
  const remoteDomainType = domains.map((d) => d.domain_name)

  data = removeAllButFields(["dialogue_mode", "direction", "domain_name", "mfw"], data)

  data.forEach((obj) => {
    if (!obj.hasOwnProperty("id")) obj.id = uuidFromCode()
    if (Object.keys(obj).length > 5) errors.add("Your routes have too many properties")

    if (!obj.hasOwnProperty("domain_name")) errors.add("Some routes are missing the 'domain_name' property")
    else if (!isNonEmptyString(obj.domain_name)) errors.add("You cannot leave the 'domain_name' property empty")
    else if (isNonEmptyString(obj.domain_name)) {
      if (!remoteDomainType.find((el) => el === obj.domain_name))
        errors.add(`The route of name '${obj.domain_name}' have no corrisponding domain`)
      if (data.filter((d) => d.domain_name === obj.domain_name).length > 2)
        errors.add("Some routes in your file have the same 'domain_name' value")
      if (data.filter((d) => d.domain_name === obj.domain_name && d.dialogue_mode === obj.dialogue_mode).length === 2)
        errors.add("Some routes in your file have the same 'domain_name' and 'dialogue_mode' values")
    }

    if (!obj.hasOwnProperty("dialogue_mode")) errors.add("Some routes are missing the 'dialogue_mode' property")
    else if (!dialogueModeType.find((el) => el === obj.dialogue_mode))
      errors.add(
        `The 'dialogue_mode' property of value '${
          obj.dialogue_mode
        }' is wrong. Choose one among: ${dialogueModeType.join(", ")}`
      )
    else {
      if (obj.dialogue_mode === "inbound") {
        if (!obj.hasOwnProperty("direction")) errors.add(`Some inbound routes are missing the 'direction' property`)
        else {
          if (!directionType.find((el) => el === obj.direction))
            errors.add(
              `Your inbound route has wrong 'direction' value '${
                obj.direction
              }'. Choose one among: ${directionType.join(", ")}`
            )
        }
        if (!obj.hasOwnProperty("mfw")) errors.add(`Some inbound routes are missing the 'mfw' property`)
        else {
          if (!mfwType.find((el) => el === obj.mfw)) {
            errors.add(
              `Your inbound routes have wrong 'mfw' value '${obj.mfw}'. Choose one among: ${mfwType.join(", ")}`
            )
          }
        }
      } else {
        if (obj.hasOwnProperty("direction")) obj.direction = ""
        if (obj.hasOwnProperty("mfw")) obj.mfw = ""
      }
    }
  })
  return { data, errors }
}

export const checkDomainImportedData = (data, seppDomain, namespace) => {
  const errors = new Set()

  try {
    data = typeof data === "string" && JSON.parse(data)
  } catch (err) {
    console.log(err)
    errors.add(`The file you provided is not in a JSON format`)
    return { data, errors }
  }

  if (!Array.isArray(data)) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }
  if (data.length === 0) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }
  if (Object.keys(data).length > 11) errors.add("Your domains have too many properties")

  const modeType = ["0", "1"]
  const hubType = ["true", "false"]
  const addressRegex = /https?:\/\/[a-zA-Z0-9.\-?=&_:]+\//g

  data = removeAllButFields(
    ["address_1", "address_2", "address_3", "address_4", "dns", "domain_name", "is_hub", "mode", "sni"],
    data
  )

  data.forEach((el) => {
    console.log(namespace, seppDomain, el.dns)
    if (!el.hasOwnProperty("id")) el.id = uuidFromCode()

    if (!el.hasOwnProperty("domain_name")) errors.add("Some domains are missing the 'domain_name' property")
    else if (!isNonEmptyString(el.domain_name)) errors.add("You cannot leave the 'domain_name' property empty")
    else if (isNonEmptyString(el.domain_name)) {
      if (el.domain_name === namespace) errors.add("Some domains have the same 'domain_name' value of this SEPP")
      if (data.filter((d) => d.domain_name === el.domain_name).length > 1)
        errors.add("Some domains in your file have the same 'domain_name' value")
    }

    if (!el.hasOwnProperty("dns")) errors.add("Some domains are missing the 'dns' property")
    else if (!isNonEmptyString(el.dns)) errors.add("You cannot leave the 'dns' property empty")
    else if (isNonEmptyString(el.dns)) {
      if (el.dns === seppDomain) errors.add("Some domains in your file have the same 'dns' value of this SEPP")
      if (data.filter((d) => d.dns === el.dns).length > 1)
        errors.add("Some domains in your file have the same 'dns' value")
    }

    if (!el.hasOwnProperty("is_hub")) el.is_hub = false
    else if (el.is_hub.toString() !== "false" && el.is_hub.toString() !== "true")
      errors.add(`Some domains have a wrong 'is_hub' value '${el.is_hub}'. Choose one among ${hubType.join(", ")}`)

    if (!el.hasOwnProperty("address_1")) errors.add("Some domains are missing the 'address_1' property")
    else if (!isNonEmptyString(el.address_1)) errors.add("You cannot leave the 'address_1' property empty")
    else if (isNonEmptyString(el.address_1)) {
      if (!el.address_1.match(addressRegex))
        errors.add(
          `Some domains have a wrong 'address_1' value '${el.address_1}'. Make sure it begins with 'http(s)://' and ends with '/'`
        )
      if (
        data.filter((d) => el.address_1 === d.address_1).length > 1 ||
        data.find((d) => el.address_1 === d.address_2) ||
        data.find((d) => el.address_1 === d.address_3) ||
        data.find((d) => el.address_1 === d.address_4)
      )
        errors.add(`Some domains have a duplicated 'address_1' value '${el.address_1}'.`)
    }

    if (isNonEmptyString(el.address_2)) {
      if (!el.address_2.match(addressRegex))
        errors.add(
          `Some domains have a wrong 'address_2' value '${el.address_2}'. Make sure it begins with 'http(s)://' and ends with '/'`
        )
      if (
        data.find((d) => el.address_2 === d.address_1) ||
        data.filter((d) => el.address_2 === d.address_2).length > 1 ||
        data.find((d) => el.address_2 === d.address_3) ||
        data.find((d) => el.address_2 === d.address_4)
      ) {
        console.log(
          data.find((d) => el.address_2 === d.address_1),
          data.filter((d) => el.address_2 === d.address_2),
          data.find((d) => el.address_2 === d.address_3),
          data.find((d) => el.address_2 === d.address_4)
        )
        errors.add(`Some domains have a duplicated 'address_2' value '${el.address_2}'.`)
      }
    }

    if (isNonEmptyString(el.address_3)) {
      if (!el.address_3.match(addressRegex))
        errors.add(
          `Some domains have a wrong 'address_3' value '${el.address_3}'. Make sure it begins with 'http(s)://' and ends with '/'`
        )
      if (
        data.find((d) => el.address_3 === d.address_1) ||
        data.find((d) => el.address_3 === d.address_2) ||
        data.filter((d) => el.address_3 === d.address_3).length > 1 ||
        data.find((d) => el.address_3 === d.address_4)
      )
        errors.add(`Some domains have a duplicated 'address_3' value '${el.address_3}'.`)
    }

    if (isNonEmptyString(el.address_4)) {
      if (!el.address_4.match(addressRegex))
        errors.add(
          `Some domains have a wrong 'address_4' value '${el.address_4}'. Make sure it begins with 'http(s)://' and ends with '/'`
        )
      if (
        data.find((d) => el.address_4 === el.address_1) ||
        data.find((d) => el.address_4 === el.address_2) ||
        data.find((d) => el.address_4 === el.address_3) ||
        data.filter((d) => el.address_4 === el.address_4).length > 1
      )
        errors.add(`Some domains have a duplicated 'address_4' value '${el.address_4}'.`)
    }

    if (!el.hasOwnProperty("sni")) errors.add("Some domains are missing the 'sni' property")
    else if (!isNonEmptyString(el.sni)) errors.add("You cannot leave the 'sni' property empty")

    if (el.hasOwnProperty("mode")) {
      if (!modeType.find((m) => m === el.mode.toString()))
        errors.add(`Some domains have a wrong 'mode' value '${el.mode}'. Choose one among ${modeType.join(", ")}`)
    }

    el.addresses = [el.address_1, el.address_2, el.address_3, el.address_4].filter((el) => isNonEmptyString(el))
  })

  return { errors, data }
}

export const checkHostnameImportedData = (data) => {
  const errors = new Set()

  try {
    data = typeof data === "string" && JSON.parse(data)
  } catch (err) {
    console.log(err)
    errors.add(`The file you provided is not in a JSON format`)
    return { data, errors }
  }

  data = removeAllButFields(["hostname", "address", "nfType"], data)

  if (!Array.isArray(data)) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }
  if (data.length === 0) {
    errors.add("The file must contain a non empty array of data")
    return { data, errors }
  }
  const ipAddressRegex = /[a-zA-Z0-9.\-?=&_:]+/g

  data.forEach((obj) => {
    if (!obj.hasOwnProperty("nfType")) obj.nfType = ""
    if (!obj.hasOwnProperty("type")) obj.type = "Manual"
    if (!obj.hasOwnProperty("id")) obj.id = uuidFromCode()
    else if (obj.type.toLowerCase() !== "manual")
      errors.add(`Some hostnames have wrong 'type' field set to '${obj.type}'. Please set it to 'Manual'`)
    if (Object.keys(obj).length > 5) errors.add("Your file has too many properties")
    if (!obj.hasOwnProperty("hostname")) errors.add("Some hostnames are missing the 'hostname' property")
    else if (!isNonEmptyString(obj.hostname))
      errors.add("You cannot leave the 'hostname' field empty because it's mandatory")
    if (!obj.hasOwnProperty("address")) errors.add("Some hostnames are missing the 'address' property")
    else if (!isNonEmptyString(obj.address))
      errors.add("You cannot leave the 'address' field empty because it's mandatory")
    else if (!obj.address.match(ipAddressRegex)) errors.add("The format of some 'address' fields is not correct")
  })
  return { data, errors }
}
